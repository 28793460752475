#root {
    margin: 0 auto;
    padding: 2rem;
    font-family: "Helvetica, Arial, sans-serif";
}

body {
    background-color: #FFFE91;
}

ol {
    margin-bottom: 0in;
}

ul {
    margin-bottom: 0in;
}

.centered {
    text-align: center;
}

.normal-text {
    font-size: 20px;
    font-weight: 200;
    line-height: 125%;
}
